/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { createSale } from "api/actions/sales";
import { getRegions } from "api/actions/region";
import { getSubRegions } from "api/actions/region";
import { setRegistrationComplete } from "api/actions/authentication";
import { setBillingCustomerCreated } from "api/actions/billing";
/// TYPES ///
import { Registration } from "api/types/authentication";
import { RegistrationErrors } from "api/types/authentication";
import { SubRegion } from "api/types/region";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
/// COMPONENTS ///
import AddressAutocomplete from "components/universal/autocomplete/geo";
/// ICONS ///
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
/// VALIDATION ///
import { validateEmail } from "functions/value";
import { validatePhoneNumber } from "functions/value";
/// COLORS ///
import { error_color } from "styles/style";
import { grey_color } from "styles/style";
import { text_primary } from "styles/style";
/// VALUES ///
import { SPONSORED_PRODUCT } from "settings";

interface Props {
  setStep: (step: number) => void
}

export default function RegistrationComponent({ setStep }: Props) {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.authentication);
  const { error } = useAppSelector((state) => state.authentication);
  const { registration_complete } = useAppSelector((state) => state.authentication);
  const { regions } = useAppSelector((state) => state.region);
  const { sub_regions } = useAppSelector((state) => state.region);
  const { product } = useAppSelector((state) => state.billing);
  const [registration, setRegistration] = useState<Registration>({ is_fabricator: false, is_distributor: true });
  const [registration_error, setRegistrationError] = useState<RegistrationErrors>({});
  const [filtered_sub_regions, setFilteredSubRegions] = useState<SubRegion[]>([]);

  useEffect(() => {
    setFilteredSubRegions(sub_regions.filter(sr => sr.region === registration.region));
  }, [sub_regions, registration?.region]);

  useEffect(() => {
    if (product === SPONSORED_PRODUCT) {
      setRegistration({ ...registration, is_fabricator: false, is_distributor: true });
    }
    else {
      setRegistration({ ...registration, is_fabricator: true, is_distributor: false });
    }
  }, [product]);

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getSubRegions());
    setRegistrationComplete(false);
    setBillingCustomerCreated(false);
  }, [dispatch]);

  if (registration_complete) {
    if (product === SPONSORED_PRODUCT) {
      setStep(5);
    }
    else {
      setStep(2);
    }
  }

  const saveString = (key: string, value: string) => {
    setRegistration({ ...registration, [key]: value });
  }

  const saveInt = (key: string, value: number) => {
    setRegistration({ ...registration, [key]: value })
  }

  const removeError = (key: string) => {
    setRegistrationError({ ...registration_error, [`${key}_error`]: "", [`${key}_has_error`]: false });
  }

  const handleRegistration = () => {
    let error: boolean = false;
    const new_registration_error = { ...registration_error };

    if (!validateEmail(registration?.email ?? "")) {
      new_registration_error.email_error = "Enter a valid email address";
      new_registration_error.email_has_error = true;
      error = true;
    }

    if (!validatePhoneNumber(registration?.phone_number ?? "")) {
      new_registration_error.phone_number_error = "Enter a valid phone number";
      new_registration_error.phone_number_has_error = true;
      error = true;
    }

    if (
      !registration?.geo_address ||
      registration?.geo_address === "" ||
      !registration?.lat ||
      registration?.lat === 0 ||
      !registration?.lng ||
      registration?.lng === 0) {
      new_registration_error.geo_error = "Must enter a service address for your location";
      new_registration_error.geo_has_error = true;
      error = true;
    }

    if (!registration?.region || registration.region === 0) {
      new_registration_error.region_error = "Must choose a service region";
      new_registration_error.region_has_error = true;
      error = true;
    }

    if (!registration?.sub_region || registration.sub_region === 0) {
      new_registration_error.sub_region_error = "Must choose a service sub region";
      new_registration_error.sub_region_has_error = true;
      error = true;
    }

    if (!registration?.first_name || registration.first_name === "") {
      new_registration_error.first_name_error = "Must enter a first name";
      new_registration_error.first_name_has_error = true;
      error = true;
    }

    if (!registration?.last_name || registration.last_name === "") {
      new_registration_error.last_name_error = "Must enter a last name";
      new_registration_error.last_name_has_error = true;
      error = true;
    }

    if (!registration?.business_name || registration.business_name === "") {
      new_registration_error.business_name_error = "Must enter a business name";
      new_registration_error.business_name_has_error = true;
      error = true;
    }

    if (!registration?.address || registration.address === "") {
      new_registration_error.address_error = "Must enter an address";
      new_registration_error.address_has_error = true;
      error = true;
    }

    if (!registration?.city || registration.city === "") {
      new_registration_error.city_error = "Must enter a city";
      new_registration_error.city_has_error = true;
      error = true;
    }

    setRegistrationError(new_registration_error);

    if (error) {
      return;
    }

    dispatch(createSale(registration));
  }

  const geoError = () => {
    setRegistrationError({
      ...registration_error,
      geo_error: "There was an issue locating this address, please choose another",
      geo_has_error: true
    })
  }

  const updateGeo = (geo_address: string, lat: number, lng: number, address: string, city: string, province: string) => {
    setRegistration({
      ...registration,
      geo_address,
      lat,
      lng,
      address,
      city,
      province
    });
    setRegistrationError({
      ...registration_error,
      geo_error: "",
      geo_has_error: false
    });
  }

  return (
    <Grid item xs={12}>
      <Stack>
        <Typography variant="h6" sx={{ color: text_primary }}>
          Account Creation
        </Typography>
        <Typography variant="body2" sx={{ color: error_color, paddingLeft: 1.5, paddingBottom: 2 }}>
          {error}
        </Typography>
        <Typography variant="body2" sx={{ color: grey_color, paddingLeft: 0.5, paddingTop: 1 }}>
          Account Owner
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            sx={{ marginBottom: 1 }}
            onFocus={() => removeError("first_name")}
            error={registration_error.first_name_has_error}
            size="small"
            label="First Name"
            id="first_name"
            onChange={e => saveString("first_name", e.target.value)} />
          <TextField
            fullWidth
            sx={{ marginBottom: 1 }}
            onFocus={() => removeError("last_name")}
            error={registration_error.last_name_has_error}
            size="small"
            label="Last Name"
            id="last_name"
            onChange={e => saveString("last_name", e.target.value)} />
        </Stack>
        <Typography variant="body2" sx={{ color: grey_color, paddingLeft: 0.5, paddingTop: 1 }}>
          Service Location
        </Typography>
        <TextField
          fullWidth
          SelectProps={{
            MenuProps: {
              sx: { height: "400px" },
            },
          }}
          sx={{ marginBottom: 1 }}
          onFocus={() => removeError("region")}
          error={registration_error.region_has_error}
          helperText={registration_error.region_error}
          size="small"
          label="Region"
          select
          defaultValue=""
          onChange={e => saveInt("region", Number(e.target.value))}>
          <MenuItem key="" value="">
          </MenuItem>
          {
            regions.map(region => {
              return (
                <MenuItem key={region.id} value={region.id}>
                  {region.name}
                </MenuItem>
              )
            })
          }
        </TextField>
        <TextField
          fullWidth
          SelectProps={{
            MenuProps: {
              sx: { height: "400px" },
            },
          }}
          sx={{ marginBottom: 1 }}
          onFocus={() => removeError("sub_region")}
          error={registration_error.sub_region_has_error}
          helperText={registration_error.sub_region_error}
          size="small"
          label="SubRegion"
          select
          defaultValue=""
          onChange={e => saveInt("sub_region", Number(e.target.value))}>
          <MenuItem key="" value="">
          </MenuItem>
          {
            filtered_sub_regions.map(sub_region => {
              return (
                <MenuItem key={sub_region.id} value={sub_region.id}>
                  {sub_region.name}
                </MenuItem>
              )
            })
          }
        </TextField>
        <Typography variant="body2" sx={{ color: grey_color, paddingLeft: 0.5, paddingTop: 1 }}>
          Business
        </Typography>
        <TextField
          fullWidth
          sx={{ marginBottom: 1 }}
          onFocus={() => removeError("business_name")}
          error={registration_error.business_name_has_error}
          helperText={registration_error.business_name_error}
          size="small"
          label="Business Name"
          id="business_name"
          onChange={e => saveString("business_name", e.target.value)} />
        <AddressAutocomplete
          label={registration?.geo_address ?? ""}
          error={registration_error?.geo_error ?? ""}
          has_error={registration_error?.geo_has_error ?? false}
          geoError={geoError}
          updateGeo={updateGeo}
          input_label="Business Location" />
        <Typography variant="body2" sx={{ color: grey_color, paddingLeft: 0.5, paddingTop: 1 }}>
          Contact
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            sx={{ marginBottom: 1 }}
            onFocus={() => removeError("email")}
            error={registration_error.email_has_error}
            size="small"
            label="Email"
            id="email"
            onChange={e => saveString("email", e.target.value)} />
          <TextField
            fullWidth
            sx={{ marginBottom: 1 }}
            onFocus={() => removeError("phone_number")}
            error={registration_error.phone_number_has_error}
            size="small"
            label="Phone: 555-555-5555"
            id="phone_number"
            onChange={e => saveString("phone_number", e.target.value)} />
        </Stack>
        <Box display="flex" justifyContent="flex-end" sx={{ marginTop: "20px" }}>
          {
            loading ?
              <CircularProgress /> :
              <Button variant="contained" endIcon={<ArrowForwardIosIcon />} onClick={handleRegistration} >
                Submit
              </Button>
          }
        </Box>
      </Stack>
    </Grid>
  );
}
